import React, { useState } from "react"
import { get } from "lodash"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom"
import Link from "shared/components/Link"
import { Formik, Form, Field } from "formik"
import Loader from "shared/components/Loader"
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient
} from "@apollo/client"

import Pill from "shared/components/Pill"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Button from "shared/components/Button"
import Wrapper from "shared/components/Wrapper"
import { List, ListItem } from "shared/containers/List"
import FormGroup from "mill/components/FormGroup"
import Label from "mill/components/Label"
import SearchBox from "mill/components/SearchBox"
import { FormikTextField as TextField } from "mill/components/TextField"

import FETCH_QUESTION_COLLECTION from "mill/graphql/FetchQuestionCollection"
import FETCH_QUESTION_CANDIDATES from "mill/graphql/FetchQuestionCollectionQuestionCandidates"
import UPDATE_QUESTION_COLLECTION from "mill/graphql/UpdateQuestionCollection"
import DELETE_QUESTION_COLLECTION from "mill/graphql/DeleteQuestionCollection"
import PROSPECTIVE_QUESTION_FRAGMENT from "mill/graphql/ProspectiveQuestionFragment"

import useConfirmationModal from "shared/hooks/useConfirmationModal"
import useNotifications from "shared/hooks/useNotifications"

import { QuestionCollectionSchema } from "mill/utils/schemas"

import { Flex, Container, BackBar } from "mill/utils/shared/core"
import {
  Well,
  SearchContainer,
  SearchResults
} from "mill/screens/Admin/Teams/Groups/Edit/styles"

const CategoryIndicator = styled.span`
  display: inline-block;
  text-align: center;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  font-weight: bold;
  margin-right: 1rem;
  border-radius: 4px;
  color: white;
  background: ${props =>
    props.category === "standard"
      ? props.theme.colors.primary
      : props.theme.colors.secondary};
`

const EditCollection = () => {
  let { id } = useParams()
  let navigate = useNavigate()
  const client = useApolloClient()
  const { showConfirmationModal } = useConfirmationModal()
  const { displayNotification } = useNotifications()
  const [questionSearchTerm, setQuestionSearchTerm] = useState("")

  const { data, loading, refetch } = useQuery(FETCH_QUESTION_COLLECTION, {
    variables: { id }
  })

  const [
    fetchQuestionCandidates,
    { loading: questionCandidatesLoading, data: questionCandidateData }
  ] = useLazyQuery(FETCH_QUESTION_CANDIDATES)
  const questionCandidates = get(
    questionCandidateData,
    "questionCollection.questionCandidates.nodes",
    []
  )

  const [updateQuestionCollection] = useMutation(UPDATE_QUESTION_COLLECTION, {
    onCompleted: data => {
      displayNotification({
        text: `${data.updateQuestionCollection.questionCollection.name} updated`,
        style: "success"
      })
    }
  })

  const [deleteQuestionCollection] = useMutation(DELETE_QUESTION_COLLECTION, {
    variables: { id },
    onCompleted: data => {
      displayNotification({
        text: `${data.deleteQuestionCollection.questionCollection.name} removed`,
        style: "success"
      })
      navigate("/admin/questions/collections")
    }
  })

  if (loading) {
    return <Loader />
  }

  const confirmRemoval = () => {
    showConfirmationModal({
      title: "Are you sure you want to remove the collection?",
      text: "That can't be easily undone; you'll need to re-create the collection from scratch if you change your mind.",
      yesText: "That's fine, remove the collection",
      yesAction: () => {
        deleteQuestionCollection({
          variables: { id }
        })
      }
    })
  }

  const handleSearchQuestions = ({ target: { value } }) => {
    setQuestionSearchTerm(value)
    if (value.length > 0) {
      fetchQuestionCandidates({
        variables: { collectionId: id, searchTerm: value }
      })
    }
  }

  const handleAddQuestion = (id, currentQuestionIds, setFieldValue) => {
    setQuestionSearchTerm("")
    setFieldValue("questionIds", [...currentQuestionIds, id])
    refetch()
  }

  const handleRemoveQuestion = (id, currentQuestionIds, setFieldValue) => {
    setQuestionSearchTerm("")
    setFieldValue(
      "questionIds",
      currentQuestionIds.filter(qId => qId !== id)
    )
  }

  const collection = data.questionCollection
  const questions = collection.questions.nodes
  const questionIds = questions.map(q => q.id)

  return (
    <Flex>
      <BackBar>
        <Wrapper>
          <Link to={"/admin/questions/collections"}>Back to collections</Link>
        </Wrapper>
      </BackBar>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingTop="large"
          paddingBottom="large">
          <Heading level={1}>Collection: {collection.name}</Heading>
          <Button
            data-qa="delete-collection"
            onClick={confirmRemoval}
            color="error"
            size="xsmall"
            label={"Remove collection"}
          />
        </Box>
        <Formik
          initialValues={{
            name: collection.name,
            questionIds: questionIds
          }}
          validationSchema={QuestionCollectionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              updateQuestionCollection({
                variables: { id: id, attributes: values }
              })
              actions.setSubmitting(false)
            }, 1000)
          }}>
          {({ values, isSubmitting, setFieldValue }) => {
            const noQuestionsOrCandidates =
              values.questionIds.length == 0 &&
              (questionCandidates.length == 0 || questionSearchTerm == "")

            return (
              <Form>
                <Field
                  fullWidth
                  component={TextField}
                  label="Name"
                  withFieldset
                  data-qa="collection-title"
                  name={"name"}
                  type="text"
                  placeholder="The yodelling yarnoers"
                />

                <FormGroup marginTop={"1.5"}>
                  <Label title="Questions">
                    Search for questions to add to the collection:
                  </Label>
                  <SearchBox
                    name="question-search"
                    data-qa="question-search"
                    full
                    placeholder="Question"
                    searchTerm={questionSearchTerm}
                    handleSearch={handleSearchQuestions}
                  />

                  <SearchContainer>
                    <SearchResults
                      visible={questionSearchTerm.length > 0}
                      data-qa="question-search-results">
                      <List>
                        {questionCandidates.map(candidate => {
                          if (values.questionIds.includes(candidate.id)) {
                            return null
                          }
                          return (
                            <ListItem
                              key={candidate.id}
                              data-qa={`result-${candidate.id}`}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              padding="small">
                              <Box display="flex" alignItems="center">
                                <CategoryIndicator
                                  category={candidate.category}>
                                  {candidate.category === "standard"
                                    ? "C"
                                    : "P"}
                                </CategoryIndicator>
                                <Box>
                                  <Text
                                    tone="dark"
                                    readableWidth
                                    display="inline">
                                    {candidate.question}
                                  </Text>
                                  {!candidate.published && (
                                    <Box
                                      display={"inline-block"}
                                      marginLeft="xsmall">
                                      <Pill color={"primary"} saturate={"0%"}>
                                        Draft
                                      </Pill>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                paddingLeft="xsmall"
                                textAlign="right"
                                style={{ minWidth: "11rem" }}>
                                <Button
                                  onClick={() =>
                                    handleAddQuestion(
                                      candidate.id,
                                      values.questionIds,
                                      setFieldValue
                                    )
                                  }
                                  label="Add question"
                                  size="xsmall"
                                  color="primary"
                                />
                              </Box>
                            </ListItem>
                          )
                        })}
                      </List>
                    </SearchResults>
                  </SearchContainer>

                  {noQuestionsOrCandidates && (
                    <Well>
                      <Text tone="dark">
                        No questions currently in the collection
                      </Text>
                    </Well>
                  )}

                  <List data-qa="collection-questions">
                    {values.questionIds.map((questionId, index) => {
                      const question =
                        questions.find(el => el.id == questionId) ||
                        client.readFragment({
                          id: questionId,
                          fragment: PROSPECTIVE_QUESTION_FRAGMENT
                        })

                      return (
                        <ListItem
                          key={questionId}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          padding="medium"
                          data-qa={`question-${questionId}`}>
                          <Box display="flex" alignItems="center">
                            <CategoryIndicator category={question.category}>
                              {question.category === "standard" ? "C" : "P"}
                            </CategoryIndicator>
                            <Box>
                              <Text
                                display={"inline"}
                                tone="dark"
                                readableWidth>
                                {question.question}
                              </Text>
                              {!question.published && (
                                <Box
                                  display={"inline-block"}
                                  marginLeft="xsmall">
                                  <Pill color={"primary"} saturate={"0%"}>
                                    Draft
                                  </Pill>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box
                            paddingLeft="xsmall"
                            textAlign="right"
                            style={{ minWidth: "14rem" }}>
                            <Button
                              onClick={() =>
                                handleRemoveQuestion(
                                  questionId,
                                  values.questionIds,
                                  setFieldValue
                                )
                              }
                              label="Remove question"
                              size="xsmall"
                              color="primary"
                              variant="hollow"
                            />
                          </Box>
                        </ListItem>
                      )
                    })}
                  </List>
                </FormGroup>

                <FormGroup marginTop={"1.5"}>
                  <Button
                    color="secondary"
                    type="submit"
                    label={"Update collection"}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <br />
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Flex>
  )
}
export default EditCollection
